<template>
  <div>
    <!-- <van-cell-group title="基础信息">
      <van-cell title="用户ID" :value="userId" />
      <van-cell title="用户名" :value="userName" />
    </van-cell-group>

    <van-cell-group>
      <van-button type="primary" @click="goProfile">更多个人信息</van-button>
    </van-cell-group> -->

  </div>
</template>

<script>
import store from "../../store";
export default {
  data() {
    return {
      userId:0,
      userName:"",
      count:"",//倒计时
    }
  },
  created(){
    this.threeGo()
  },
  methods: {
    //预定时间后跳转页面
    threeGo(){
      const TIME_COUNT = 3;
      if(!this.timer){
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(()=>{
        if(this.count > 0 && this.count <= TIME_COUNT){
          this.count--;
        }else{
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
          //跳转的页面写在此处
          this.$router.replace({
            path: '/healCard'
          }).catch(err => {
            console.log(err);
          });
        }
        },1000)
      }
    },
    goProfile() {
      this.$router.replace("/user/profile");
    }
  },
  mounted() {
    let me = this;
    me.userId = store.getters["user/id"];
    me.userName = store.getters["user/userName"];
  }
}
</script>

<style>
</style>
